///include /assets/js/plugins/network-property-set.js
/**
 * @typedef {GET.Stack.Payment.$ns.paymentItem.card.id.output & {type: "card"}} sCardPaymentItem
 */
/**
 * @typedef {GET.Stack.Payment.$ns.paymentItem.debit.id.output & {type: "debit"}} sDirectDebitPaymentItem
 */
/**
 * @typedef {GET.Stack.Payment.$ns.paymentItem.paypal.id.output & {type: "paypal"}} sPayPalPaymentItem
 */
/**
 * @typedef {GET.Stack.Payment.$ns.paymentItem.paystack.id.output & {type: "paystack"}} sPaystackPaymentItem
 */
/**
 * @typedef {GET.Stack.Payment.$ns.paymentItem.razorpay.id.output & {type: "razorpay"}} sPaystackPaymentItem
 */
/**
 * @typedef {GET.Stack.Payment.$ns.paymentItem.paddle.id.output & {type: "paddle"}} sPaddlePaymentItem
 */
/**
 * @typedef {GET.Stack.Payment.$ns.paymentItem.transfer.id.output & {type: "transfer"}} sTransferPaymentItem
 */
/**
 * @typedef {sCardPaymentItem | sDirectDebitPaymentItem | sPayPalPaymentItem | sPaystackPaymentItem | sPaddlePaymentItem | sTransferPaymentItem} sAnyPaymentItem
 */
/**
 * Class for a payment item
 */
class SPaymentItem extends NetworkPropertySet {
    /**
     * @type {string} URL for images for card types and direct debits
     */
    static get paymentTypeThumbnail() {
        return "/assets/images/cards/unknown.svg"
    }
    /**
     * Load the back-end data
     * @param {string} type
     * @returns {typeof SPaymentItem}
     */
    static classFor(type) {
        switch(type) {
            case "balance": return SPaymentItemBalance
            case "card": return SPaymentItemCard
            case "debit": return SPaymentItemDirectDebit
            case "paypal": return SPaymentItemPayPal
            case "paystack": return SPaymentItemPaystack
            case "paddle": return SPaymentItemPaddle
            case "transfer": return SPaymentItemTransfer
            case "razorpay": return SPaymentItemRazorpay
            default: return SPaymentItem
        }
    }
    /**
     * Load the back-end data
     * @param {sAnyPaymentItem} item
     */
    static item(item) {
        const c = this.classFor(item.type)
        return new c(item)
    }
    /**
     * Load the back-end data
     * @param {sAnyPaymentItem} item
     */
    constructor(item) {
        super()
        this.id = item.id
        /**
         * @type {?GET.Stack.Payment.$ns.paymentItem.card.id.info.output}
         */
        this.info = null
        this.type = item.type
        this.isBillable = item.isBillable
        this.display = item.display ?? null;
    }

    get networkPropertyHandlers() {
        return {
            info: () => $.ajax(`/n/payment/paymentItem/${this.type}/${this.id}/info`)
        }
    }

    /**
     * @type {?string} eg. "GBR"
     */
    get country() {
        return null
    }

    /**
     * @type {object}
     */
    get authorisation_data() {
        return {
            expirydate: this.expiresBy
        };
    }

    /**
     * @type {object}
     */
    get display_data() {
        return {
            card_product_type_desc: (this.display ? this.display.typeName : this.label),
        };
    }

    /**
     * @type {boolean} true if the payment item has expired
     */
    get expired() {
        return false
    }

    /**
     * @type {?string} The issuer label
     */
    get issuer() {
        return null
    }

    /**
     * @type {string} human-readable label for the item
     */
    get label() {
        if( this.display ** this.display.pan ){
            return "Ending in "+this.display.pan.split(" ")[2];
        } else {
            return "Unknown"
        }
    }

    /**
     * @type {string} URL for images for card types and direct debits
     */
    get paymentItemThumbnail() {
        //@ts-ignore
        return this.constructor.paymentTypeThumbnail
    }
    /**
     * eg. card:42
     */
    get ref() {
        return `${this.type}:${this.id}`
    }
}

class SPaymentItemBalance extends SPaymentItem {
    /**
     * @type {string} URL for images for card types and direct debits
     */
    static get paymentTypeThumbnail() {
        return "/assets/images/payment-gateways/small-icons/balance.svg"
    }

    /**
     * Load the back-end data
     * @param {sCardPaymentItem} item
     */
     constructor(item) {
        super(item)
    }

    /**
     * @type {string} URL for images for card types and direct debits
     */
     get paymentItemThumbnail() {
        return "/assets/images/payment-gateways/small-icons/balance.svg"
    }
}

class SPaymentItemCard extends SPaymentItem {
    /**
     * @type {string} URL for images for card types and direct debits
     */
    static get paymentTypeThumbnail() {
        return "/assets/images/icons/platforms_bs/credit-card.svg"
    }

    /**
     * Load the back-end data
     * @param {sCardPaymentItem} item
     */
    constructor(item) {
        super(item)
        this.expiresBy = new Date(item.expiresBy)
        this.display = item.display
        this.description = item.description
    }

    /**
     * @type {?string} The card issuer label
     */
    get cardIssuer() {
        return this.issuer
    }

    /**
     * @type {?string} eg. "GBR"
     */
    get country() {
        return this.display.country
    }

    /**
     * @type {boolean} true if the payment item has expired
     */
    get expired() {
        return this.expiresBy < new Date()
    }

    /**
     * @type {?string} The issuer label
     */
    get issuer() {
        return this.display.issuer
    }

    /**
     * @type {string} human-readable label for the item
     */
    get label() {
        if(this.display.pan) {
            return `Ending in ${this.display.pan.substring(this.display.pan.length - 4)} (Expires ${Site.Format.dateString(this.expiresBy)?.substring(3)})`
        } else if(this.expiresBy) {
            if(this.expiresBy < new Date()) {
                return `Recently added card, no longer usable`
            } else {
                return `Recently added card, must be used before ${this.expiresBy.toLocaleTimeString()}`
            }
        } else {
            return `(New card)`
        }
    }

    /**
     * @type {string} URL for images for card types and direct debits
     */
    get paymentItemThumbnail() {
        if(this.display.schemeName) {
            const fileNamePart =
                this.display.schemeName.toLowerCase().replace(/ /g,"-")
            switch( fileNamePart ){
                case 'mci-credit':
                    return "/assets/images/cards/mastercard.svg"
                default:
                    return `/assets/images/cards/${fileNamePart}.svg`
            }
        } else {
            return super.paymentItemThumbnail
        }
    }
}

class SPaymentItemDirectDebit extends SPaymentItem {
    /**
     * @type {string} URL for images for card types and direct debits
     */
    static get paymentTypeThumbnail() {
        return "/assets/images/cards/directdebit.svg"
    }

    /**
     * Load the back-end data
     * @param {sDirectDebitPaymentItem} item
     */
    constructor(item) {
        super(item)
        this.display = item.display
    }

    /**
     * @type {?string} eg. "GBR"
     */
    get country() {
        return "GBR"
    }

    /**
     * @type {string} human-readable label for the itemq
     */
    get label() {
        return `Ending in ${this.display.accountNumber.substring(this.display.accountNumber.length - 4)}`
    }
}

class SPaymentItemPayPal extends SPaymentItem {
    /**
     * @type {string} URL for images for card types and direct debits
     */
    static get paymentTypeThumbnail() {
        return "/assets/images/cards/paypal.svg"
    }

    /**
     * Load the back-end data
     * @param {sPayPalPaymentItem} item
     */
    constructor(item) {
        super(item)
        this.display = item.display
    }

    /**
     * @type {?string} eg. "GBR"
     */
    get country() {
        throw new Error("Not implemented")
    }

    /**
     * @type {string} human-readable label for the item
     */
    get label() {
        return `PayPal`
    }
}

class SPaymentItemRazorpay extends SPaymentItem {
    /**
     * @type {string} URL for images for card types and direct debits
     */
    static get paymentTypeThumbnail() {
        return "/assets/images/cards/razorpay.svg"
    }

    /**
     * Load the back-end data
     * @param {sRazorpayPaymentItem} item
     */
    constructor(item) {
        super(item)
        this.display = item.display
        this.expiresBy = new Date(item.expiresBy)
    }

    /**
     * @type {?string} The card issuer label
     */
    get cardIssuer() {
        return this.display.issuer
    }

    /**
     * @type {?string} eg. "GBR"
     */
    get country() {
        return this.display.country
    }

    /**
     * @type {boolean} true if the payment item has expired
     */
    get expired() {
        return this.expiresBy < new Date()
    }

    /**
     * @type {?string} The issuer label
     */
    get issuer() {
        return this.display.issuer
    }

    /**
     * @type {string} human-readable label for the item
     */
    get label() {
        if(this.display.pan) {
            return `Ending in ${this.display.pan.substring(this.display.pan.length - 4)} (Expires ${Site.Format.dateString(this.expiresBy)?.substring(3)})`
        } else if(this.expiresBy) {
            if(this.expiresBy < new Date()) {
                return `Recently added card, no longer usable`
            } else {
                return `Recently added card, must be used before ${this.expiresBy.toLocaleTimeString()}`
            }
        } else {
            return `(New card)`
        }
    }

    /**
     * @type {string} URL for images for card types and direct debits
     */
    get paymentItemThumbnail() {
        return "/assets/images/icons/platforms_bs/credit-card.svg"
    }
}

class SPaymentItemPaystack extends SPaymentItem {
    /**
     * @type {string} URL for images for card types and direct debits
     */
    static get paymentTypeThumbnail() {
        return "/assets/images/cards/paystack.svg"
    }

    /**
     * Load the back-end data
     * @param {sPaystackPaymentItem} item
     */
    constructor(item) {
        super(item)
        this.display = item.display
        this.expiresBy = new Date(item.expiresBy)
    }

    /**
     * @type {?string} The card issuer label
     */
    get cardIssuer() {
        return this.display.issuer
    }

    /**
     * @type {?string} eg. "GBR"
     */
    get country() {
        return this.display.country
    }

    /**
     * @type {boolean} true if the payment item has expired
     */
    get expired() {
        return this.expiresBy < new Date()
    }

    /**
     * @type {?string} The issuer label
     */
    get issuer() {
        return this.display.issuer
    }

    /**
     * @type {string} human-readable label for the item
     */
    get label() {
        if(this.display.pan) {
            return `Ending in ${this.display.pan.substring(this.display.pan.length - 4)} (Expires ${Site.Format.dateString(this.expiresBy)?.substring(3)})`
        } else if(this.expiresBy) {
            if(this.expiresBy < new Date()) {
                return `Recently added card, no longer usable`
            } else {
                return `Recently added card, must be used before ${this.expiresBy.toLocaleTimeString()}`
            }
        } else {
            return `(New card)`
        }
    }

    /**
     * @type {string} URL for images for card types and direct debits
     */
    get paymentItemThumbnail() {
        return "/assets/images/icons/platforms_bs/credit-card.svg"
    }
}

class SPaymentItemPaddle extends SPaymentItem {
    /**
     * @type {string} URL for images for card types and direct debits
     */
    static get paymentTypeThumbnail() {
        return "/assets/images/cards/paddle.svg"
    }

    /**
     * Load the back-end data
     * @param {sPaddlePaymentItem} item
     */
    constructor(item) {
        super(item)
        this.display = item.display
        this.expiresBy = new Date(item.expiresBy)
    }

    /**
     * @type {?string} The card issuer label
     */
    get cardIssuer() {
        return this.display.issuer
    }

    /**
     * @type {?string} eg. "GBR"
     */
    get country() {
        return this.display.country
    }

    /**
     * @type {boolean} true if the payment item has expired
     */
    get expired() {
        return this.expiresBy < new Date()
    }

    /**
     * @type {?string} The issuer label
     */
    get issuer() {
        return this.display.issuer
    }

    /**
     * @type {string} human-readable label for the item
     */
    get label() {
        if(this.display.pan) {
            return `Ending in ${this.display.pan.substring(this.display.pan.length - 4)} (Expires ${Site.Format.dateString(this.expiresBy)?.substring(3)})`
        } else if(this.expiresBy) {
            if(this.expiresBy < new Date()) {
                return `Recently added card, no longer usable`
            } else {
                return `Recently added card, must be used before ${this.expiresBy.toLocaleTimeString()}`
            }
        } else {
            return `(New card)`
        }
    }

    /**
     * @type {string} URL for images for card types and direct debits
     */
    get paymentItemThumbnail() {
        return "/assets/images/icons/platforms_bs/credit-card.svg"
    }
}

class SPaymentItemTransfer extends SPaymentItem {
    /**
     * @type {string} URL for images for card types and direct debits
     */
    static get paymentTypeThumbnail() {
        return "/assets/images/cards/transfer.svg"
    }

    /**
     * Load the back-end data
     * @param {sTransferPaymentItem} item
     */
    constructor(item) {
        super(item)
        this.display = item.display
    }

    /**
     * @type {?string} eg. "GBR"
     */
    get country() {
        return null
    }

    /**
     * @type {string} human-readable label for the item
     */
    get label() {
        return `Bank Transfer/Cheque/Other`
    }
}

class PaymentItemCollection extends NetworkPropertySet {
    static get inst() {
        if(!this._inst) {
            this._inst = new PaymentItemCollection()
        }
        return this._inst
    }
    constructor() {
        super()
        /** @type {?{[ref: string]: string}} */
        this.countries = null
        /** @type {?SPaymentItem[]} */
        this.items = null
    }
    get networkPropertyHandlers() {
        return {
            countries: async () => {
                let items
                try {
                    items = await this.preloadSingle("items")
                } catch(e) {
                    return {}
                }
                /** @type {{[ref: string]: string}} */
                const payment_item_countries = {}
                for(const item of items) {
                    try {
                        if(item.country) {
                            payment_item_countries[item.id] = item.country
                        }
                    } catch(e) {
                        console.log(e)
                    }
                }

                return payment_item_countries
            },
            countriesFlat: async () => Object.values(
                await this.preloadSingle("countries")
            ),
            items: () => $.ajax(`/n/payment/paymentItem`).then(
                /** @param {sAnyPaymentItem[]} result */
                result => {
                    const payment_items = result.map(
                        pi => SPaymentItem.item(pi)
                    )
                    payment_items.sort(
                        (a,b) => +a.expired - +b.expired
                    )
                    return $.Deferred().resolve(payment_items)
                },
                (r, s, jqxhr) => {
                    if(r.status != 401) {
                        ShowNotification.fail(
                            "There was a problem fetching payment items."
                        )
                    }
                    return Promise.reject(r)
                }
            )
        }
    }
    /**
     * @param {string} type
     * @param {string} name
     * @returns {Promise<SPaymentItem>}
     */
    async namedItem(type, name) {
        /** @type {sAnyPaymentItem} */
        const pi = await $.ajax(`/n/payment/paymentItem/${type}/${name}`)
        return SPaymentItem.item(pi)
    }
}
